import { useEffect } from 'react';
import './App.css'

import { motion } from 'framer-motion';

interface ProjectProps {
  image: string;
  title: string;
  description: string;
  url: string;
}

const my = {
  name: "Kedruga",
  email: "invitation@kedruga.com"
}

const projects = [
  {
    image: "https://res.cloudinary.com/djq1huf8u/image/upload/f_auto,q_auto/clucoin_jzs59v.png",
    title: "CluCoin",
    description: "Website Development, Smart Contract Development, $150M market cap",
    url: "https://www.clucoin.com"
  },
  {
    image: "https://res.cloudinary.com/djq1huf8u/image/upload/f_auto,q_auto/eternal_z6b5xh.png",
    title: "Eternal",
    description: "Website Development, $4.5M VC funding",
    url: "https://eternal.gg"
  },
  {
    image: "https://res.cloudinary.com/djq1huf8u/image/upload/f_auto,q_auto/goobers_nft_edyj6y.png",
    title: "Goobers NFT",
    description: "Branding, Website Development, $2M sales",
    url: "https://www.goobers.net"
  },
  {
    image: "https://res.cloudinary.com/djq1huf8u/image/upload/f_auto,q_auto/xenia_zdhtyk.png",
    title: "Xenia",
    description: "Website Development",
    url: "https://xenia.gg"
  }
]

const socials = [
  {
    title: "Twitter",
    url: "https://twitter.com/kedruga",
  }
]

const variants = {
  offscreen: { opacity: 0, y: "100px" },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeInOut",
      duration: 1
    }
  },
}

const Arrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="7" y1="17" x2="17" y2="7"></line><polyline points="7 7 17 7 17 17"></polyline></svg>
  )
}

const Navbar = () => {
  return (
    <nav>
      <motion.ul
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={variants}
      >
        <li><span className="title"><strong>{my.name}</strong></span></li>
        <li><a className="email link" href={`mailto:${my.email}`}>{my.email}<Arrow /></a></li>
      </motion.ul>
    </nav>
  )
}

const Project = ({ image, title, description, url }: ProjectProps) => {
  return (
    <motion.div
      className="project"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      variants={variants}
    >
      <a href={url} target="_blank" rel="noreferrer noopener">
        <motion.img
          src={image}
          alt={title}
          initial={{ opacity: 1 }}
          whileHover={{ opacity: 0.7 }}
        />
        <p className="title"><strong>{title}</strong></p>
        <p className="description">{description}</p>
      </a>
    </motion.div>

  );
};

const Projects = () => {
  return (
    <section className="projects">
      {projects.map((project, i) =>
        <Project
          key={i}
          image={project.image}
          title={project.title}
          description={project.description}
          url={project.url}
        />
      )}
    </section>
  )
}

const About = () => {
  return (
    <motion.section
      className="about"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      variants={variants}
    >
      <h2>About</h2>
      <p>
        We helped blockchain startups raise $millions in Venture and ICO fundings.
      </p>
    </motion.section>
  )
}

const Footer = () => {
  return (
    <motion.footer
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      variants={variants}
    >
      {socials.map((social, i) =>
        <a
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
          key={i}
        >
          {social.title}
        </a>
      )}
    </motion.footer>
  )
}

const App = () => {
  useEffect(() => {
    (window as any).team = [
      {
        name: "Musab Shakil",
        title: "CEO and Cofounder"
      },
      {
        name: "Muhammad Shaheer",
        title: "CMO and Cofounder"
      }
    ]
  }, [])
  
  return (
    <>
      <Navbar />
      <main>
        <Projects />
        <About />
      </main>
      <Footer />
    </>
  );
};

export default App;
